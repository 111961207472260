<template>
  <div>
    <article class="main-content">

      <OrderedComponents
        :data="content"
      />

    </article>
  </div>
</template>

<script setup>
import { productDynamic } from '~/graphql/pageQuery'

const { data } = await useAsyncQuery(productDynamic, { slug: '404' })

const content = data.value.productdynamics.data[0].attributes.page

const metaTitle = ref('')
const metaDescription = ref('')

useHead({
  title: metaTitle,
  meta: [ { name: 'description', content: metaDescription } ]
})

metaTitle.value = data.value.productdynamics.data[0].attributes.metatitle || 'Ростелеком Контакт-Центр'
metaDescription.value = data.value.productdynamics.data[0].attributes.metadescription || ''

</script>
